import * as R from 'ramda';

const getImages = (image, breakpoints) =>
  R.map(R.prop(R.__, image))(breakpoints);

const getWidths = R.map(R.compose(R.concat(R.__, 'w'), R.toString));

const getThemeBreakpoints = R.compose(
  R.sort(R.subtract),
  R.values,
  R.pick(['xs', 'sm', 'md', 'lg']),
  R.path(['breakpoints', 'values'])
);

const getBreakpointPairs = (breakpoints) =>
  R.zip(
    R.map(R.dec, R.tail(breakpoints)),
    breakpoints
  );

const makeImageBreakpoint = image => breakpoints =>
  R.join(', ',
    R.zipWith(
      (x, y) => R.concat(x, R.concat(' ', y)),
      getImages(image, breakpoints),
      getWidths(breakpoints)
    )
  );

const makeMediaQueries = R.compose(
  R.join(', '),
  R.map(([a, b]) => `(max-width: ${a}px) ${b}px`),
  getBreakpointPairs
);

/**
 * Create a responsive img srcset attribute from image and theme objects.
 *
 * @param {object} image
 * @param {Theme} theme
 * @returns {string}
 */
export const makeSrcSet = R.curry(
  (image, theme) => R.compose(
    makeImageBreakpoint(image),
    getThemeBreakpoints
  )(theme)
);

/**
 * Creates a responsive img sizes attribute from theme.
 *
 * @param {Theme} theme
 * @returns {string}
 */
export const makeSizes = (theme) => {
  const breakpoints = getThemeBreakpoints(theme);
  const end = R.last(breakpoints);

  return R.compose(
    R.concat(R.__, `, ${end}px`),
    makeMediaQueries,
  )(breakpoints);
};
