import * as R from 'ramda';
import openWindows320 from './assets/hero/open-windows-320w.jpg';
import openWindows480 from './assets/hero/open-windows-480w.jpg';
import openWindows720 from './assets/hero/open-windows-720w.jpg';
import openWindows910 from './assets/hero/open-windows-910w.jpg';
import backlitBros320 from './assets/hero/backlit-bros-320w.jpg';
import backlitBros480 from './assets/hero/backlit-bros-480w.jpg';
import backlitBros720 from './assets/hero/backlit-bros-720w.jpg';
import backlitBros910 from './assets/hero/backlit-bros-910w.jpg';
import churchTrees320 from './assets/hero/church-trees-320w.jpg';
import churchTrees480 from './assets/hero/church-trees-480w.jpg';
import churchTrees720 from './assets/hero/church-trees-720w.jpg';
import churchTrees910 from './assets/hero/church-trees-910w.jpg';
import escalatorStare320 from './assets/hero/escalator-stare-320w.jpg';
import escalatorStare480 from './assets/hero/escalator-stare-480w.jpg';
import escalatorStare720 from './assets/hero/escalator-stare-720w.jpg';
import escalatorStare910 from './assets/hero/escalator-stare-910w.jpg';
import fourShoes320 from './assets/hero/four-shoes-320w.jpg';
import fourShoes480 from './assets/hero/four-shoes-480w.jpg';
import fourShoes720 from './assets/hero/four-shoes-720w.jpg';
import fourShoes910 from './assets/hero/four-shoes-910w.jpg';
import rose320 from './assets/hero/rose-320w.jpg';
import rose480 from './assets/hero/rose-480w.jpg';
import rose720 from './assets/hero/rose-720w.jpg';
import rose910 from './assets/hero/rose-910w.jpg';
import rougeBeach320 from './assets/hero/rouge-beach-320w.jpg';
import rougeBeach480 from './assets/hero/rouge-beach-480w.jpg';
import rougeBeach720 from './assets/hero/rouge-beach-720w.jpg';
import rougeBeach910 from './assets/hero/rouge-beach-910w.jpg';
import sugarFog320 from './assets/hero/sugar-fog-320w.jpg';
import sugarFog480 from './assets/hero/sugar-fog-480w.jpg';
import sugarFog720 from './assets/hero/sugar-fog-720w.jpg';
import sugarFog910 from './assets/hero/sugar-fog-910w.jpg';
import whiteBridge320 from './assets/hero/white-bridge-320w.jpg';
import whiteBridge480 from './assets/hero/white-bridge-480w.jpg';
import whiteBridge720 from './assets/hero/white-bridge-720w.jpg';
import whiteBridge910 from './assets/hero/white-bridge-910w.jpg';

const heroImages = {
  'open-windows': {
    title: 'Open Windows',
    320: openWindows320,
    480: openWindows480,
    720: openWindows720,
    910: openWindows910,
  },
  'backlit-bros': {
    title: 'Backlit Bros.',
    320: backlitBros320,
    480: backlitBros480,
    720: backlitBros720,
    910: backlitBros910,
  },
  'church-trees': {
    title: 'St. Andrew\'s Trees',
    320: churchTrees320,
    480: churchTrees480,
    720: churchTrees720,
    910: churchTrees910,
  },
  'escalator-stare': {
    title: 'Escalator Stare',
    320: escalatorStare320,
    480: escalatorStare480,
    720: escalatorStare720,
    910: escalatorStare910,
  },
  'four-shoes': {
    title: 'Four Shoes',
    320: fourShoes320,
    480: fourShoes480,
    720: fourShoes720,
    910: fourShoes910,
  },
  'rose': {
    title: 'Rose',
    320: rose320,
    480: rose480,
    720: rose720,
    910: rose910,
  },
  'rouge-beach': {
    title: 'Rouge Beach',
    320: rougeBeach320,
    480: rougeBeach480,
    720: rougeBeach720,
    910: rougeBeach910,
  },
  'sugar-fog': {
    title: 'Sugar Fog',
    320: sugarFog320,
    480: sugarFog480,
    720: sugarFog720,
    910: sugarFog910,
  },
  'white-bridge': {
    title: 'White Bridge',
    320: whiteBridge320,
    480: whiteBridge480,
    720: whiteBridge720,
    910: whiteBridge910,
  },
};

const getRandomInt = R.compose(
  Math.floor,
  R.multiply(Math.random()),
  Math.floor
);

const pickRandom = (list) => R.compose(
  R.partialRight(R.nth, [R.values(list)]),
  getRandomInt,
  R.prop('length'),
  R.values
)(list);

const randomHeroImage = () => pickRandom(heroImages)

export default randomHeroImage;