import * as R from 'ramda';

export const isReactSnap = R.compose(
  R.equals('ReactSnap'),
  R.prop('userAgent')
);

export const isSafari = R.compose(
  R.match(/Safari/),
  R.prop('userAgent')
);