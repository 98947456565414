import React from 'react';
import * as R from 'ramda';
import { withTheme } from '@material-ui/core/styles';
import { withWidth } from '@material-ui/core';
import { isReactSnap, isSafari } from '../utils/userAgent';
import { isProduction } from '../utils/env';
import { makeSrcSet, makeSizes } from '../utils/responsive';
import randomHeroImage from "../randomHeroImage";

const isPreRendering = (n, p) => R.and(isReactSnap(n), isProduction(p));

const isNotPreRendering = R.complement(isPreRendering);

const createKey = (navigator, breakpoint) =>
  isSafari(navigator) ? breakpoint : 'lg';

const HeroImage = ({ theme, width, image = randomHeroImage() }) => {
  const key = createKey(navigator, width);
  const srcSet = makeSrcSet(image, theme);
  const sizes = makeSizes(theme);

  return (
    <>
      {isNotPreRendering(navigator, process) &&
        <img
          key={key}
          srcSet={srcSet}
          sizes={sizes}
          src={image[theme.breakpoints.values['lg']]}
          alt={image.title}
        />
      }
    </>
  );
};

export default withWidth()(withTheme()(HeroImage));