import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import InstagramIcon from 'mdi-material-ui/Instagram';
import classNames from 'classnames';
import HeroImage from '../components/HeroImage';
import withLayout from '../withLayout';

const styles = theme => ({
  h1: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    fontSize: '5rem',
    textAlign: 'right',
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.down('md')]: {
      fontSize: '4.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.8rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.7rem',
    },
  },
  blurb: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  icon: {
    paddingRight: theme.spacing.unit,
  },
  section: {
    marginBottom: theme.spacing.unit * 3
  }
});

const Index = ({ classes }) => {
  return (
    <>
      <header className={classes.section}>
        <Typography variant="h1" className={classes.h1}>Mike Dahlke</Typography>
        <HeroImage />
      </header>
      <section className={classNames(classes.blurb, classes.section)}>
        <Typography variant="body1">
          <strong>Mike Dahlke</strong> is a Toronto based photographer specializing in
          traditional film and darkroom techniques with a practical focus on urban and
          observational photography.
        </Typography>
      </section>
      <footer className={classes.section}>
        <div>
          <Button variant="contained" color="primary" className={classes.button} href="mailto:yourstruly@mikedahlke.com">
            <EmailIcon className={classes.icon} />
            yourstruly@mikedahlke.com
          </Button>
        </div>
        <div>
          <Button variant="contained" color="primary" className={classes.button} href="https://www.instagram.com/instapoid/">
            <InstagramIcon className={classes.icon} />
            @instapoid
          </Button>
        </div>
      </footer>
    </>
  );
};

export default withLayout(withStyles(styles)(Index));