import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: '#212121',
      light: '#484848',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#90a4ae',
      light: '#c1d5e0',
      dark: '#62757f',
      contrastText: '#000',
    },
  },
  typography: {
    useNextVariants: true,
  },
  breakpoints: {
    values: {
      'xs': 320,
      'sm': 480,
      'md': 720,
      'lg': 910,
      'xl': 1920
    },
  },
});

const styles = theme => ({
  root: {
    maxWidth: '910px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.breakpoints.values.md,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.breakpoints.values.sm,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: theme.breakpoints.values.xs,
    },
  },
});

const Root = withStyles(styles)(props => (
  <div className={props.classes.root}>
    {props.children}
  </div>
));

export default (Component) =>
  (props) => {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <Root>
          <Component {...props} />
        </Root>
      </MuiThemeProvider>
    );
  };
